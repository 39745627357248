
















































































































import { Component, Ref, Vue } from 'vue-property-decorator';
import AppApi from '@/api/application/AppApi';
import { getUsualFlows } from '@/api/flow-design/FlowApi';
import CusMenuItem from '@/views/flow-deal/components/cus-menu-item.vue';
import TokenUtil from 'global-ui/packages/utils/TokenUtil';
import BodyHeader from '@/views/main/components/body-header.vue';

@Component({
  name: 'UserHomeFlowCreate',
  components: {
    CusMenuItem,
    BodyHeader
  }
})
export default class UserHomeFlowCreate extends Vue {
  // 颜色数组
  colors: any[] = [
    'rgb(48,199,144)',
    'rgb(170,106,250)',
    'rgb(252,198,72)',
    'rgb(250,104,99)',
    'rgb(141, 206, 54)'
  ];

  // 所有数据
  allDataList: any[] = [];

  // 默认选中的应用树
  defaultActiveMenu: string = '';

  // 应用树类型加载状态
  typeTreeLoading: boolean = false;
  // 应用树类型数据
  typeTreeData: any[] = [
    {
      disabled: false,
      id: '-99999',
      text: this.$t('lang_common_processes'),
      type: '3'
    }
  ];

  // 流程列表加载状态
  flowListLoading: boolean = false;
  // 流程列表数据
  flowListData: any[] = [];
  flowListData2: any[] = [];
  // 常用流程列表
  usualFlows: any[] = [];

  headerTitle: string = '';
  created() {
    this.getTypeTreeData();
  }
  get _user() {
    return this.$store.getters.user;
  }
  /**
   * 应用类型树点击
   * @param data
   * @param node
   * @param el
   */
  typeTreeNodeClick(data: { code: string; type: string; id: any }, node: any, el: any) {
    this.headerTitle = data.code;
    if (data.type === '0') {
      // 从所有数据中筛选出 id 相等的数据
      let filter = this.allDataList.filter((item) => item.id === data.id);
      if (filter.length > 0) {
        this.flowListData = filter[0].children;
        this.flowListData2 = filter[0].children;
      }
    } else if (data.type === '1') {
      // 获取应用类型树下指定类型数据
      let typeTreeDataForType = this.getTypeTreeDataForType(this.allDataList, '1');
      if (typeTreeDataForType.length > 0) {
        let filter = typeTreeDataForType.filter((item) => item.id === data.id);
        if (filter.length > 0) {
          this.flowListData = [filter[0]];
          this.flowListData2 = [filter[0]];
        }
      }
    } else if (data.type === '3') {
      this.flowListData = this.usualFlows;
      this.flowListData2 = this.usualFlows;
    }
  }

  /**
   * 删除应用类型树下指定类型数据
   * @param arr
   * @param type
   */
  delTreeNodeType(arr: any[], type: string) {
    if (!arr) return [];
    let idx = 0;
    const list = JSON.parse(JSON.stringify(arr));
    list.forEach((item: { type: string }, index: number) => {
      if (item.type === type) {
        arr.splice(index - idx, 1);
        idx++;
      } else {
        this.delTreeNodeType(arr[index].children, type);
      }
    });
    return arr;
  }
  search(values: any) {
    let list = [];
    this.flowListData2.forEach((item, index) => {
      for (let i = 0; i < item.children.length; i++) {
        if (item.children[i].text.includes(values)) {
          list.push(item);
        }
      }
    });
    let last = new Set(list);
    console.log([...last]);
    this.flowListData = [...last];
  }

  /**
   * 获取应用类型树下指定类型数据
   * @param dataList
   * @param type
   */
  getTypeTreeDataForType(dataList: any[], type: string) {
    let res: any[] = [];
    dataList.forEach((item: any) => {
      if (item.type === type) {
        res.push(item);
      }
      if (item.children && item.children.length > 0) {
        res.push(...this.getTypeTreeDataForType(item.children, type));
      }
    });
    return res;
  }

  /**
   * 获取应用类型数据
   */
  getTypeTreeData() {
    this.typeTreeLoading = true;
    getUsualFlows({ count: 8 }).then((res: any) => {
      if (res.code == '1') {
        if (res.data) {
          this.usualFlows = [
            {
              disabled: true,
              id: '-999991',
              text: this.$t('lang_common_processes'),
              type: '1',
              children: []
            }
          ];
          res.data.list.forEach((element: { id: any; displayName: any }) => {
            this.usualFlows[0].children.push({
              disabled: true,
              id: element.id,
              text: element.displayName,
              type: '2'
            });
          });
        } else {
          this.usualFlows = [];
        }
        this.flowListData.push(this.usualFlows[0]);
        this.flowListData2.push(this.usualFlows[0]);
        
        AppApi.applicationLinkQueryCodeTree({
          linkType: 2,
          type: 0,
          scopeUser: this._user.id
        })
          .then((res: any) => {
            if (res.code == 1) {
              this.allDataList = JSON.parse(JSON.stringify(res.data));

              // 左侧流程分类
              let tmp = JSON.parse(JSON.stringify(res.data));
              this.delTreeNodeType(tmp, '1').forEach((element) => {
                this.typeTreeData.push(element);
              });
              this.getTypeTreeDataForType(res.data, '1').forEach((item: any) => {
                this.flowListData.push(item);
                this.flowListData2.push(item);
              });
            }
          })
          .finally(() => {
            this.typeTreeLoading = false;
          });
      }
    });
  }

  /**
   * 返回首页
   */
  goToHome() {
    this.$router.push({ path: '/home' });
  }

  /**
   * 跳转流程
   * @param item
   */
  jumpFlow(item: { id: any }) {
    let path = this.$router.resolve({
      path: '/front2/start',
      query: {
        id: item.id
      }
    });
    window.open(path.href, '_blank');
  }
}
